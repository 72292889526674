import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { userConstent } from "../_constents/_constents";
import { userServices } from '../_services/_services';

const initialState = {

};

export const login = createAsyncThunk(
  "authentication/login",
  async (userData, { rejectWithValue }) => {
    try {
      const user = await userServices.login(userData);
      toast.success("User logged in successfully.");

      return user;
    } catch (error) {
      toast.error("Invalid Login Details");

      return rejectWithValue(error.message);
    }
  }
);

export const defaultSetting = createAsyncThunk(
  "authentication/defaultSetting",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.defaultSetting(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const customerDetails = createAsyncThunk(
  "authentication/customerDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.customerDetails(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const userchatlist = createAsyncThunk(
  "authentication/userchatlist",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.userchatlist(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const checkAvailable = createAsyncThunk(
  "authentication/checkAvailable",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.checkAvailable(data);
      if (response?.error == false) {
        toast.success(response?.message)
        return response.data;
      } else {
        toast.error(response?.message)
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const signUp = createAsyncThunk(
  "authentication/signUp",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await userServices.signUp(data);
      if (response?.error == false) {
        // toast.success(response?.message)
        const loginData = {
          "user_name": data.username,
          "password": data.password
        };

        await dispatch(login(loginData));
        return response.data;
      } else {
        toast.error(response?.message)
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const existMobile = createAsyncThunk(
  "authentication/existMobile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.existMobile(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const ourSlides = createAsyncThunk(
  "authentication/ourSlides",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.ourSlides(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const walletBalance = createAsyncThunk(
  "authentication/walletBalance",
  async (navigate, { rejectWithValue }) => {
    try {
      const response = await userServices.walletBalance(navigate);
      if (response?.error == false) {
        return response.data;
      } else {
        toast.error(response?.message)
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const myBetList = createAsyncThunk(
  "authentication/myBetList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.myBetList(data);
      if (response?.error == false) {
        return response.data;
      } else {
        toast.error(response?.message)
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const profitLoss = createAsyncThunk(
  "authentication/profitLoss",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.profitLoss(data);
      if (response?.error == false) {
        return response.data;
      } else {
        toast.error(response?.message)
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const accountStatement = createAsyncThunk(
  "authentication/accountStatement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.accountStatement(data);
      if (response?.error == false) {
        return response.data;
      } else {
        toast.error(response?.message)
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateInfo = createAsyncThunk(
  "authentication/updateInfo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.updateInfo(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SportMatchOuterList = createAsyncThunk(
  "authentication/SportMatchOuterList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.SportMatchOuterList(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const matchSeriesList = createAsyncThunk(
  "authentication/matchSeriesList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.matchSeriesList(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCasinoList = createAsyncThunk(
  "authentication/getCasinoList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.getCasinoList(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const casinoListByCategory = createAsyncThunk(
  "authentication/casinoListByCategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.casinoListByCategory(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const eventGameList = createAsyncThunk(
  "authentication/eventGameList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.eventGameList(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const gameEventList = createAsyncThunk(
  "authentication/gameEventList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.gameEventList(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSportMatchList = createAsyncThunk(
  "authentication/getSportMatchList",
  async (_, { rejectWithValue }) => {
    try {
      const sportMatchList = await userServices.sportMatchList();
      return sportMatchList;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// userBalance getUserBalance

export const getUserBalance = createAsyncThunk(
  'authentication/getUserBalance',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userServices.getUserBalance(payload);
      if (response?.data) {
        localStorage.setItem('userBalance', JSON.stringify(response.data.coins));
        localStorage.setItem('userExposure', JSON.stringify(response.data.exposure));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getMatchDetails = createAsyncThunk(
  'authentication/getMatchDetails',
  async (data, { rejectWithValue }) => {
    try {
      const response = await userServices.getMatchDetails(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getStatement = createAsyncThunk(
  'authentication/getStatement',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userServices.getStatement(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  'authentication/changePassword',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userServices.changePassword(payload);

      if (response?.error == false) {
        return response.data;
      } else {
        toast.error(response?.message)
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const betHistory = createAsyncThunk(
  'authentication/betHistory',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userServices.betHistory(payload);

      return response.betHistory;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getUserLedger = createAsyncThunk(
  'authentication/getUserLedger',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userServices.getUserLedger(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getstackUpdate = createAsyncThunk(
  'authentication/single-click-update-amount',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userServices.getstackUpdate(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadepositeFile = createAsyncThunk(
  'authentication/uploadepositeFile',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userServices.uploadepositeFile(payload);
      if (response?.error == false) {
        return response.data;
      } else {
        toast.error(response?.message)
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const depositRequest = createAsyncThunk(
  'authentication/depositRequest',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userServices.depositRequest(payload);
      if (response?.error == false) {
        toast.success(response?.message)
        return response.data;
      } else {
        toast.error(response?.message)
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const ourPages = createAsyncThunk(
  'authentication/ourPages',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userServices.ourPages(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const ourPagesShow = createAsyncThunk(
  'authentication/ourPagesShow',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userServices.ourPagesShow(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout(state) {
      state.loggedIn = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.loggedIn = true;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSportMatchList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSportMatchList.fulfilled, (state, action) => {
        state.loading = false;
        state.sportmatchlist = action.payload;
      })
      .addCase(getSportMatchList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getMatchDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMatchDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.matchDetails = action.payload;
      })
      .addCase(getMatchDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getStatement.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatement.fulfilled, (state, action) => {
        state.loading = false;
        state.accountStatement = action.payload;
      })
      .addCase(getStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;

      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(betHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(betHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.betHistoryList = action.payload;
      })
      .addCase(betHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // getUserLedger

      .addCase(getUserLedger.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserLedger.fulfilled, (state, action) => {
        state.loading = false;
        state.userLedger = action.payload;
      })
      .addCase(getUserLedger.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // userBalance getUserBalance

      .addCase(getUserBalance.pending, (state) => {
        state.balanceLoading = true;
      })
      .addCase(getUserBalance.fulfilled, (state, action) => {
        state.balanceLoading = false;
        state.userBalance = action.payload;
      })
      .addCase(getUserBalance.rejected, (state, action) => {
        state.balanceLoading = false;
        state.error = action.payload;
      })

      // get default setting data

      .addCase(defaultSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(defaultSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.defaultSettingData = action.payload;
      })
      .addCase(defaultSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // customer Details  data

      .addCase(customerDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(customerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.customerDetailsData = action.payload;
      })
      .addCase(customerDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // user chat list  data

      .addCase(userchatlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(userchatlist.fulfilled, (state, action) => {
        state.loading = false;
        state.userchatlistData = action.payload;
      })
      .addCase(userchatlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // get our slides data

      .addCase(ourSlides.pending, (state) => {
        state.loading = true;
      })
      .addCase(ourSlides.fulfilled, (state, action) => {
        state.loading = false;
        state.ourSlidesData = action.payload;
      })
      .addCase(ourSlides.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // get sport list data

      .addCase(SportMatchOuterList.pending, (state) => {
        state.loading = true;
      })
      .addCase(SportMatchOuterList.fulfilled, (state, action) => {
        state.loading = false;
        state.SportMatchOuterListData = action.payload;
      })
      .addCase(SportMatchOuterList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // get match series list

      .addCase(matchSeriesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(matchSeriesList.fulfilled, (state, action) => {
        state.loading = false;
        state.matchSeriesListData = action.payload;
      })
      .addCase(matchSeriesList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // get casino list by category

      .addCase(casinoListByCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(casinoListByCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.casinoListByCategoryData = action.payload;
      })
      .addCase(casinoListByCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // get event game list by

      .addCase(eventGameList.pending, (state) => {
        state.loading = true;
      })
      .addCase(eventGameList.fulfilled, (state, action) => {
        state.loading = false;
        state.eventGameListData = action.payload;
      })
      .addCase(eventGameList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // get casino list

      .addCase(getCasinoList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCasinoList.fulfilled, (state, action) => {
        state.loading = false;
        state.getCasinoListData = action.payload;
      })
      .addCase(getCasinoList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // game event list

      .addCase(gameEventList.pending, (state) => {
        state.loading = true;
      })
      .addCase(gameEventList.fulfilled, (state, action) => {
        state.loading = false;
        state.gameEventListData = action.payload;
      })
      .addCase(gameEventList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // wallet balance data

      .addCase(walletBalance.pending, (state) => {
        // state.loading = true;
      })
      .addCase(walletBalance.fulfilled, (state, action) => {
        // state.loading = false;
        state.walletBalanceData = action.payload;
      })
      .addCase(walletBalance.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload;
      })

      // upadte info data

      .addCase(updateInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.updateInfoData = action.payload;
      })
      .addCase(updateInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //account statement data

      .addCase(accountStatement.pending, (state) => {
        state.loading = true;
      })
      .addCase(accountStatement.fulfilled, (state, action) => {
        state.loading = false;
        state.accountStatementData = action.payload;
      })
      .addCase(accountStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })


      // upload depositre file data

      .addCase(uploadepositeFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadepositeFile.fulfilled, (state, action) => {
        state.loading = false;
        state.uploadepositeFileData = action.payload;
      })
      .addCase(uploadepositeFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //my bet list data

      .addCase(myBetList.pending, (state) => {
        state.loading = true;
      })
      .addCase(myBetList.fulfilled, (state, action) => {
        state.loading = false;
        state.myBetListData = action.payload;
      })
      .addCase(myBetList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //profit loss data

      .addCase(profitLoss.pending, (state) => {
        state.loading = true;
      })
      .addCase(profitLoss.fulfilled, (state, action) => {
        state.loading = false;
        state.profitLossData = action.payload;
      })
      .addCase(profitLoss.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //Stake Update

      .addCase(getstackUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getstackUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.stackUpdateData = action.payload;
      })
      .addCase(getstackUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //check available

      .addCase(checkAvailable.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkAvailable.fulfilled, (state, action) => {
        state.loading = false;
        state.checkAvailableData = action.payload;
      })
      .addCase(checkAvailable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })


      //Exist mobile number or not

      .addCase(existMobile.pending, (state) => {
        state.loading = true;
      })
      .addCase(existMobile.fulfilled, (state, action) => {
        state.loading = false;
        state.existMobileData = action.payload;
      })
      .addCase(existMobile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //sign up

      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.signUpData = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //deposit request

      .addCase(depositRequest.pending, (state) => {
        state.loading = true;

      })
      .addCase(depositRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.depositRequestData = action.payload;

      })
      .addCase(depositRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //our services

      .addCase(ourPages.pending, (state) => {
        state.loading = true;

      })
      .addCase(ourPages.fulfilled, (state, action) => {
        state.loading = false;
        state.ourPagesData = action.payload;

      })
      .addCase(ourPages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(ourPagesShow.pending, (state) => {
        state.loading = true;

      })
      .addCase(ourPagesShow.fulfilled, (state, action) => {
        state.loading = false;
        state.ourPagesShowData = action.payload;

      })
      .addCase(ourPagesShow.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { logout } = authenticationSlice.actions;

export default authenticationSlice.reducer;