import React, { useEffect, useState } from "react";
import { getStatement } from "../../../redux/_reducers/_reducers";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";
import { accountStatement } from "../../../redux/_reducers/_reducers";
import RightSidebar from "../../../components/RightSidebar/RightSidebar";
import ReactPaginate from "react-paginate";

function AccountStatement() {
  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').unix());
  const [toDate, setToDate] = useState(moment().unix());
  const [selectedType, setSelectedType] = useState('Data');
  const [fetchedData, setFetchedData] = useState([]);
  const [statementData, setStatementData] = useState([]);
  const [page, setPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = page;

  const handleDateChange = (e, type) => {
    const value = e.target.value;

    const unixTimestamp = Math.floor(new Date(value).getTime() / 1000);

    if (type === 'from') {
      setFromDate(unixTimestamp);
    } else if (type === 'to') {
      setToDate(unixTimestamp);
    }
  };

  const handlePageNumber = (event) => {
    setPage(Number(event.target.value));
  };

  const { accountStatementData, loading } = useSelector(
    (state) => state.authentication
  );

  useEffect(() => {
    if (accountStatementData) {
      setStatementData(accountStatementData);
    }
  }, [accountStatementData]);

  const dispatch = useDispatch();

  const changeData = (fetchedData) => {
    if (fetchedData) {
      const updatedLedgerData = fetchedData.map((data, index) => {
        return {
          Date: moment.unix(data.created_at).utcOffset("+05:30").format("DD-MM-YYYY"),
          SrNo: index + 1,
          Creditamount: parseFloat(data.amount > 0 ? data.amount : 0).toFixed(2).replace(/\.?0+$/, ''),
          Debitamount: parseFloat(data.amount <= 0 ? data.amount : 0).toFixed(2).replace(/\.?0+$/, ''),
          Description: data.description,
          Balance: data.available_balance,
        };
      });
      setFetchedData(updatedLedgerData);
    }
  };

  const formatDate = (unixTimestamp) => {
    if (!unixTimestamp) return "";
    const date = new Date(unixTimestamp * 1000);
    return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${String(date.getFullYear()).slice(-2)}`;
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (fromDate && toDate) {
      const fetchData = async () => {
        try {
          let reqData = {
            "from_date": fromDate,
            "to_date": toDate,
            "limit": page,
            "pageno": 1,
          };
          const response = await dispatch(accountStatement(reqData));
          setFetchedData(response.payload);
          changeData(response.payload?.data)
        } catch (err) {
          console.error('Failed to fetch statement:', err);
        }
      };
      fetchData();
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [page]);

  return (
    <>
      <div className="score-tabcontent-work px-2">
        {loading && <Loader />}
        <form className="row rollingform1">
          <div className="col-lg-4 col-md-4  col-12  my-profile-select mt-2">
            <input className="position-relative abc"
              type="date" id="date"
              value={moment.unix(fromDate).format("YYYY-MM-DD")}
              onChange={(e) => handleDateChange(e, 'from')}
            />
          </div>

          <div className="col-lg-4 col-md-4 col-12 my-profile-select mt-2">
            <input className="position-relative abc" type="date" id="date"
              value={moment.unix(toDate).format("YYYY-MM-DD")}
              onChange={(e) => handleDateChange(e, 'to')}
            />
          </div>

          <div className="col-lg-4 col-md-4 col-12 my-profile-select mt-2">
            <button onClick={handleSubmit} className="form-btn border-0">Search</button>
          </div>
        </form>

        <div className="account-statment-tablwork">
          <div className="account-statment-table">
            <div className="roling-table border">
              <div className="rolling-commission-div">
                <h4>Account Statement</h4>
              </div>
              <div className="commission-table account-statment-tables">
                <div className="account-lable d-flex flex-wrap align-items-center">
                  <p className="mb-0">Show</p>
                  <label className="mx-1">
                    <select
                      value={page}
                      onChange={handlePageNumber}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </label>
                  <p className="mb-0">entries</p>
                </div>
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div className="d-flex flex-nowrap align-items-center justify-content-center">
                          <p>Date</p>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="d-flex flex-nowrap align-items-center justify-content-center">
                          <p>Credit</p>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="d-flex flex-nowrap align-items-center justify-content-center">
                          <p>Debit</p>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="d-flex flex-nowrap align-items-center justify-content-center">
                          <p>Balance</p>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="d-flex flex-nowrap align-items-center justify-content-center">
                          <p>Remarks</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {statementData && statementData.length > 0 ? (
                      statementData.map((ele, i) => (
                        <tr key={i}>
                          <td className="border text-center">
                          {moment.unix(ele.created_at).format("DD MMM YYYY HH:MM A")}
                          </td>
                          <td
                            className="border text-center"
                            style={{ color: ele?.amount < 0 ? 'red' : 'green' }}
                          >
                            {ele?.amount < 0 ? '-' : ele?.amount}
                          </td>
                          <td
                            className="border text-center"
                            style={{ color: ele?.amount < 0 ? 'red' : 'green' }}
                          >
                            {ele?.amount < 0 ? ele?.amount : '-'}
                          </td>
                          <td
                            className="border text-center"
                            style={{ color: ele?.available_balance < 0 ? 'red' : 'green' }}
                          >
                            {ele?.available_balance < 0 ? ele?.available_balance : ele?.available_balance}
                          </td>
                          <td className="border text-center whitespaceNowrap">
                            {ele?.description}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colspan="5" className="text-center">
                          <p>No data!</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* <div className="howing-content row justify-content-between">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(statementData.length / itemsPerPage)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-lg-3 score-tabbets " style={{top:'100px'}}>
        <RightSidebar/>
        </div> */}
    </>
  );
}

export default AccountStatement;
