
import React, { useEffect, useRef, useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import wp from '../../assets/image/whatsapplogo.png'
import { RiWhatsappLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { customerDetails, userchatlist, depositRequest } from "../../redux/_reducers/_reducers";
import { CONST } from "../../middelware/Const";
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { httpPostFormData } from "../../middelware/Http";
import Loader from "../../components/Loader/Loader";


function Deposit() {

    const [Isdeposit, Setdeposit] = useState(0);
    const [imgData, SetImgData] = useState();
    const [defaultAllData, setDefaultAllData] = useState()
    const [utrNumber, setUtrNumber] = useState("");
    const [utrNumberTwo, setUtrNumberTwo] = useState("");
    const [paymentProof, setPaymentProof] = useState(null);
    const [paymentProofTwo, setPaymentProofTwo] = useState(null);
    const [amount, setAmount] = useState("");
    const [amountTwo, setAmountTwo] = useState("");
    const [iconClicked, setIconClicked] = useState(false);
    const [textToCopy, setTextToCopy] = useState('');
    const [copySuccess, setCopySuccess] = useState('');
    const [loading, setLoading] = useState(false)
    const [utrError, setUtrError] = useState('');
    const [paymentProofError, setPaymentProofError] = useState('');
    const [amountError, setAmountError] = useState('');
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const handleUtrChange = (e) => setUtrNumber(e.target.value);
    const handleAmountChange = (e) => setAmount(e.target.value);
    const { defaultSettingData, customerDetailsData, userchatlistData } = useSelector((state) => state.authentication);

    const DepositClick = (index) => {
        Setdeposit(index);
        setUtrNumber("");
        setAmount("");
        setPaymentProof('');
    };

    const handlePaymentProofChange = (e) => {
        setPaymentProof('')
        const uploadedFile = e.target.files[0];
        setPaymentProof(uploadedFile);
        if (uploadedFile) {
            uploadFileToServer(uploadedFile);
        }
    };

    const handleCopy = (bank_name) => {
        navigator.clipboard.writeText(bank_name)
            .then(() => {
                setIconClicked(true); toast.success('Copied', { autoClose: 2000 });
            })
            .catch(() => {
                setCopySuccess('Failed to copy text.'); toast.error('Failed to copy text.', { autoClose: 2000 });
            });
    };


    const uploadFileToServer = async (file) => {
        if (file) {
            const formData = new FormData();

            formData.append('deposite', file);
            try {
                let fileData = await httpPostFormData('uploadDepositeFile', formData);
                if (fileData && fileData.message) {
                    toast.success(fileData.message);
                    SetImgData(fileData?.data?.attachment)
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    const customerData = () => {
        const payload = { "type": "D" };
        dispatch(customerDetails(payload));
    }

    const userChatListData = () => {

        const data = {
            "limit": 15,
            "page": 1,
            "status": "AL",
            "type": "D"
        };
        dispatch(userchatlist(data));
    }

    useEffect(() => {
        userChatListData();
        customerData();
    }, [dispatch]);


    useEffect(() => {
        if (defaultSettingData) {
            setDefaultAllData(defaultSettingData);
        }
    }, [defaultSettingData]);

    const whatsappData = defaultAllData?.find((item) => item.key === "site.whatsapp");

    const handleSubmit = async (element) => {
        setUtrError('');
        setPaymentProofError('');
        setAmountError('');

        let isValid = true;

        if (!utrNumber) {
            setUtrError('Please enter a valid UTR number.');
            isValid = false;
        } else if (utrNumber.length < 6 || utrNumber.length > 12) {
            setUtrError('UTR number must be between 6 to 12 digits.');
            isValid = false;
        }

        if (!paymentProof) {
            setPaymentProofError('Please upload your payment proof.');
            isValid = false;
        }

        if (Number(amount) < 100) {
            setAmountError('Amount must be at least 100.');
            isValid = false;
        }

        if (!isValid) {
            return;
        }
        setLoading(true)
        const data = {
            "amount": Number(amount),
            "account_number": element?.account_phone_number,
            "ifsc": element?.account_ifsc_code,
            "account_name": element?.account_holder_name,
            "type": "D",
            "user_id": 20,
            "transaction_id": utrNumber,
            "sender": "",
            "reciver": "",
            "dwtype": "D",
            "method": element.account_type,
            "attachment": imgData
        }
        try {
            await dispatch(depositRequest(data));
            userChatListData();

            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }

        } catch (error) {
            console.error("API call failed", error);
            toast.error("Submission failed. Please try again.");
        }
        setLoading(false)
        setUtrNumber("");
        setAmount("");
        setPaymentProof('');
    };


    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     let isValid = true;

    //     setUtrError('');
    //     setPaymentProofError('');
    //     setAmountError('');

    //     if (!utrNumber) {
    //         setUtrError('Please enter a valid UTR number.');
    //         isValid = false;
    //     } else if (utrNumber.length < 6 || utrNumber.length > 12) {
    //         setUtrError('UTR number must be between 6 to 12 digits.');
    //         isValid = false;
    //     }

    //     if (!paymentProof) {
    //         setPaymentProofError('Please upload your payment proof.');
    //         isValid = false;
    //     }

    //     if (!amount) {
    //         setAmountError('Please enter a valid amount.');
    //         isValid = false;
    //     }




    // };

    return (
        <>
            {loading && <Loader />}
            <div className="row mx-auto w-100">
                <div className="col-lg-9 col-12 pe-lg-0 mt-3">
                    <div className="row mx-0">
                        <div className="col-12 px-0 scoretabcontent-work shadow-none d-flex flex-column flex-sm-row align-items-start pb-2">
                            <div className="tabs w-100">
                                <div className="tab-headers d-flex flex-wrap justify-content-center py-3 gap-2 bg-white rounded-2">
                                    <Link to={whatsappData?.value}
                                        className={`wpDeposit cursor-pointer rounded-2`}>
                                        <div className="text-uppercase">WHATSAPP DEPOSIT</div>
                                        <img src={wp} />
                                    </Link>

                                    {customerDetailsData && customerDetailsData.length > 0 ? customerDetailsData.map((element, index) => (
                                        <div
                                            onClick={() => DepositClick(index)}
                                            className={`deposit-tab cursor-pointer ${Isdeposit === index ? 'active-deposit text-white' : 'bg-gray-300'}`}>
                                            {element.account_type === "IMPS" ?
                                                <>
                                                    <h4 className="text-uppercase">Account</h4>
                                                    <img src="/images/bank-account.png" />
                                                </> :
                                                <>
                                                    <h4 className="text-uppercase">upi</h4>
                                                    <img src="/images/upi-icon.png" />
                                                </>}
                                        </div>
                                    ))
                                        : null}

                                </div>
                                {customerDetailsData && customerDetailsData.length > 0 ? customerDetailsData.map((element, index) => (
                                    <div className="tab-content mt-2">
                                        {Isdeposit === index &&
                                            <div>
                                                {element.account_type === "IMPS" ?
                                                    <>
                                                        <div className="">
                                                            <div className='depositbank bg-white p-4 rounded-2'>
                                                                <h3>BANK ACCOUNT</h3>
                                                                <div className='row'>
                                                                    <div className="col-lg-6 col-12">
                                                                        <div className='account-detail-deposit'>
                                                                            <div className='depositbank-detail'>
                                                                                <ul>
                                                                                    <li className='d-flex flex-wrap justify-content-between'>
                                                                                        <div className='d-flex flex-wrap bankname'>
                                                                                            <div className="bankName">Bank Name :</div>
                                                                                            <span className="bankSubName">{element?.bank_name}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div className='bankcopy text-end' onClick={() => handleCopy(element?.bank_name)}>
                                                                                                {iconClicked ? <IoCopyOutline /> : <IoCopyOutline />}
                                                                                            </div>
                                                                                            {copySuccess && <p>{copySuccess}</p>}
                                                                                        </div>
                                                                                        {/* <div className='bankcopy text-end'><IoCopyOutline /></div> */}
                                                                                    </li>
                                                                                    <li className='d-flex flex-wrap justify-content-between'>
                                                                                        <div className='d-flex flex-wrap bankname'>
                                                                                            <div className="bankName">A/C Number :</div>
                                                                                            <span className="bankSubName">{element?.account_phone_number}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div className='bankcopy text-end' onClick={() => handleCopy(element?.account_phone_number)}>
                                                                                                {iconClicked ? <IoCopyOutline /> : <IoCopyOutline />}
                                                                                            </div>
                                                                                            {copySuccess && <p>{copySuccess}</p>}
                                                                                        </div>
                                                                                        {/* <div className='bankcopy text-end'><IoCopyOutline /></div> */}
                                                                                    </li>
                                                                                    <li className='d-flex flex-wrap justify-content-between'>
                                                                                        <div className='d-flex flex-wrap bankname'>
                                                                                            <div className="bankName">IFSC Code :</div>
                                                                                            <span className="bankSubName">{element?.account_ifsc_code}</span>
                                                                                        </div>

                                                                                        <div>
                                                                                            <div className='bankcopy text-end' onClick={() => handleCopy(element?.account_ifsc_code)}>
                                                                                                {iconClicked ? <IoCopyOutline /> : <IoCopyOutline />}
                                                                                            </div>
                                                                                            {copySuccess && <p>{copySuccess}</p>}
                                                                                        </div>

                                                                                        {/* <div className='bankcopy text-end'><IoCopyOutline /></div> */}
                                                                                    </li>
                                                                                    <li className='d-flex flex-wrap justify-content-between'>
                                                                                        <div className='d-flex flex-wrap bankname'>
                                                                                            <div className="bankName">Account Name :</div>
                                                                                            <span className="bankSubName">{element?.account_holder_name}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div className='bankcopy text-end' onClick={() => handleCopy(element?.account_holder_name)}>
                                                                                                {iconClicked ? <IoCopyOutline /> : <IoCopyOutline />}
                                                                                            </div>
                                                                                            {copySuccess && <p>{copySuccess}</p>}
                                                                                        </div>
                                                                                        {/* <div className='bankcopy text-end'><IoCopyOutline /></div> */}
                                                                                    </li>
                                                                                    <li className='d-flex flex-wrap justify-content-between'>
                                                                                        <div className='d-flex flex-wrap bankname'>
                                                                                            <div className="bankName">Min Amount :</div>
                                                                                            <span className="bankSubName">{element?.min_diposit}</span>
                                                                                        </div>

                                                                                        <div>
                                                                                            <div className='bankcopy text-end' onClick={() => handleCopy(element?.min_diposit)}>
                                                                                                {iconClicked ? <IoCopyOutline /> : <IoCopyOutline />}
                                                                                            </div>
                                                                                            {copySuccess && <p>{copySuccess}</p>}
                                                                                        </div>
                                                                                        {/* <div className='bankcopy text-end'><IoCopyOutline /></div> */}
                                                                                    </li>
                                                                                    <li className='d-flex flex-wrap justify-content-between'>
                                                                                        <div className='d-flex flex-wrap bankname'>
                                                                                            <div className="bankName">Max Amount :</div>
                                                                                            <span className="bankSubName">{element?.max_diposit}</span>
                                                                                        </div>

                                                                                        <div>
                                                                                            <div className='bankcopy text-end' onClick={() => handleCopy(element?.max_diposit)}>
                                                                                                {iconClicked ? <IoCopyOutline /> : <IoCopyOutline />}
                                                                                            </div>
                                                                                            {copySuccess && <p>{copySuccess}</p>}
                                                                                        </div>
                                                                                        {/* <div className='bankcopy text-end'><IoCopyOutline /></div> */}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="wpBtnWrapper">
                                                                            <Link to={whatsappData?.value} className="wpBtn w-100 d-flex flex-column align-items-center">FOR PAYMENT RELATED ISSUES <span className="wpAnimation"><RiWhatsappLine /></span></Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-12 mt-4 mt-lg-0">
                                                                        <div>
                                                                            <div className='depositform-data'>
                                                                                <div className='input'>
                                                                                    <label>Unique Transaction Reference <span>*</span></label>
                                                                                    <input type='text'
                                                                                        value={utrNumber}
                                                                                        onChange={handleUtrChange}
                                                                                        placeholder='6 to 12 Digits UTR Number'
                                                                                        className='d-block depositInp' />
                                                                                    {/* <span>Enter valid UTR number.</span>  */}
                                                                                    {utrError && <span className="error">{utrError}</span>}
                                                                                </div>

                                                                                <div className="my-3 upload-container">
                                                                                    <label htmlFor="paymentProof" className="form-label">
                                                                                        Upload Your Payment Proof{" "}
                                                                                        <span className="required-text">[Required]</span>
                                                                                    </label>
                                                                                    <input type="hidden" name="hiddenInput" />
                                                                                    <input
                                                                                        ref={fileInputRef}
                                                                                        onChange={handlePaymentProofChange}
                                                                                        type="file"
                                                                                        className="form-control custom-file-input"
                                                                                        id="paymentProof"
                                                                                        aria-describedby="paymentProofHelp"
                                                                                    />
                                                                                    <div></div>
                                                                                    {/* <div>
                                                                                    <p></p>
                                                                                </div> */}
                                                                                    {paymentProofError && <span className="error text-danger">{paymentProofError}</span>}
                                                                                </div>

                                                                                <div className='input'>
                                                                                    <label>Amount<span>*</span></label>
                                                                                    <input
                                                                                        type='number'
                                                                                        value={amount}
                                                                                        onChange={handleAmountChange}
                                                                                        placeholder='Please Enter Minimum 100 Rs.'
                                                                                        className='d-block depositInp' />
                                                                                    {amountError && <span className="error">{amountError}</span>}
                                                                                    {/* <span>Enter valid UTR number.</span> */}
                                                                                </div>
                                                                                <div><div onClick={() => handleSubmit(element)} className="submitBtn submitBtnUpi">SUBMIT</div></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <ul className="noteWrapper mt-3 rounded-2">
                                                                <li className="list">1.  Deposit money only in the below available accounts to get the fastest credits and avoid possible delays. </li>
                                                                <li className="list">2.Deposits made 45 minutes after the account removal from the site are valid & will be added to their wallets.</li>
                                                                <li className="list">3. Site is not responsible for money deposited to Old, Inactive or Closed accounts.</li>
                                                                <li className="list">4. After deposit, add your UTR and amount to receive balance.</li>
                                                                <li className="list">5 .NEFT receiving time varies from 40 minutes to 2 hours.</li>
                                                                <li className="list">6. In case of account modification: payment valid for 1 hour after changing account details in deposit page.</li>
                                                            </ul>
                                                        </div>
                                                    </> : <>
                                                        <div className="">
                                                            <div className='depositbank bg-white p-4 rounded-2'>
                                                                <h3>UPI</h3>
                                                                <div className='row'>
                                                                    <div className="col-lg-6 col-12">
                                                                        <div className='account-detail-deposit'>
                                                                            <div className='depositbank-detail'>
                                                                                <ul>
                                                                                    <li className='d-flex flex-wrap justify-content-between'>
                                                                                        <div className='d-flex flex-wrap bankname'>
                                                                                            <div className="bankName">Name :</div>
                                                                                            <span className="bankSubName">{element?.account_holder_name}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div className='bankcopy text-end' onClick={() => handleCopy(element?.account_holder_name)}>
                                                                                                {iconClicked ? <IoCopyOutline /> : <IoCopyOutline />}
                                                                                            </div>
                                                                                            {copySuccess && <p>{copySuccess}</p>}
                                                                                        </div>
                                                                                        {/* <div className='bankcopy text-end'><IoCopyOutline /></div> */}
                                                                                    </li>
                                                                                    <li className='d-flex flex-wrap justify-content-between'>
                                                                                        <div className='d-flex flex-wrap bankname'>
                                                                                            <div className="bankName">UPI ID :</div>
                                                                                            <span className="bankSubName">{element?.upi_id}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div className='bankcopy text-end' onClick={() => handleCopy(element?.upi_id)}>
                                                                                                {iconClicked ? <IoCopyOutline /> : <IoCopyOutline />}
                                                                                            </div>
                                                                                            {copySuccess && <p>{copySuccess}</p>}
                                                                                        </div>
                                                                                        {/* <div className='bankcopy text-end'><IoCopyOutline /></div> */}
                                                                                    </li>
                                                                                    <li className='d-flex flex-wrap justify-content-between'>
                                                                                        <div className='d-flex flex-wrap bankname'>
                                                                                            <div className="bankName">Min Amount :</div>
                                                                                            <span className="bankSubName">{element?.min_diposit}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div className='bankcopy text-end' onClick={() => handleCopy(element?.min_diposit)}>
                                                                                                {iconClicked ? <IoCopyOutline /> : <IoCopyOutline />}
                                                                                            </div>
                                                                                            {copySuccess && <p>{copySuccess}</p>}
                                                                                        </div>
                                                                                        {/* <div className='bankcopy text-end'><IoCopyOutline /></div> */}
                                                                                    </li>
                                                                                    <li className='d-flex flex-wrap justify-content-between'>
                                                                                        <div className='d-flex flex-wrap bankname'>
                                                                                            <div className="bankName">Max Amount :</div>
                                                                                            <span className="bankSubName">{element?.max_diposit}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div className='bankcopy text-end' onClick={() => handleCopy(element?.max_diposit)}>
                                                                                                {iconClicked ? <IoCopyOutline /> : <IoCopyOutline />}
                                                                                            </div>
                                                                                            {copySuccess && <p>{copySuccess}</p>}
                                                                                        </div>
                                                                                        {/* <div className='bankcopy text-end'><IoCopyOutline /></div> */}
                                                                                    </li>
                                                                                    <li className="d-flex justify-content-center">
                                                                                        <img className="qrimage" src={`${CONST.PAYMENT_URL}${element?.attachment}`} alt="" />
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="wpBtnWrapper">
                                                                            <Link to={whatsappData?.value} className="wpBtn w-100 d-flex flex-column align-items-center">FOR PAYMENT RELATED ISSUES <span className="wpAnimation"><RiWhatsappLine /></span></Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-12 mt-4 sm-mt-0">
                                                                        <div>
                                                                            <div className='depositform-data'>
                                                                                <div className='input'>
                                                                                    <label>Unique Transaction Reference <span>*</span></label>
                                                                                    <input type='text'
                                                                                        value={utrNumber}
                                                                                        onChange={handleUtrChange}
                                                                                        placeholder='6 to 12 Digits UTR Number'
                                                                                        className='d-block depositInp' />
                                                                                    {/* <span>Enter valid UTR number.</span>  */}
                                                                                    {utrError && <span className="error">{utrError}</span>}
                                                                                </div>

                                                                                <div className="my-3 upload-container">
                                                                                    <label htmlFor="paymentProof" className="form-label">
                                                                                        Upload Your Payment Proof{" "}
                                                                                        <span className="required-text">[Required]</span>
                                                                                    </label>
                                                                                    <input type="hidden" name="hiddenInput" />
                                                                                    <input
                                                                                        onChange={handlePaymentProofChange}
                                                                                        type="file"
                                                                                        className="form-control custom-file-input"
                                                                                        id="paymentProof"
                                                                                        aria-describedby="paymentProofHelp"
                                                                                    />
                                                                                    <div></div>
                                                                                    {paymentProofError && <span className="error text-danger">{paymentProofError}</span>}
                                                                                </div>

                                                                                <div className='input'>
                                                                                    <label>Amount<span>*</span></label>
                                                                                    <input
                                                                                        type='number'
                                                                                        value={amount}
                                                                                        onChange={handleAmountChange}
                                                                                        placeholder='6 to 12 Digits UTR Number'
                                                                                        className='d-block depositInp' />
                                                                                    {amountError && <span className="error">{amountError}</span>}
                                                                                    {/* <span>Enter valid UTR number.</span> */}
                                                                                </div>
                                                                                <div><div onClick={() => handleSubmit(element)} className="submitBtn submitBtnUpi">SUBMIT</div></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <ul className="noteWrapper mt-3 rounded-2">
                                                                <li className="list">1.  Deposit money only in the below available accounts to get the fastest credits and avoid possible delays. </li>
                                                                <li className="list">2.Deposits made 45 minutes after the account removal from the site are valid & will be added to their wallets.</li>
                                                                <li className="list">3. Site is not responsible for money deposited to Old, Inactive or Closed accounts.</li>
                                                                <li className="list">4. After deposit, add your UTR and amount to receive balance.</li>
                                                                <li className="list">5 .NEFT receiving time varies from 40 minutes to 2 hours.</li>
                                                                <li className="list">6. In case of account modification: payment valid for 1 hour after changing account details in deposit page.</li>
                                                            </ul>
                                                        </div>
                                                    </>}
                                            </div>}
                                        {/* {Isdeposit === 1 &&
                                        <div>
                                            <>
                                                <div className="">
                                                    <div className='depositbank bg-white p-4 rounded-2'>
                                                        <h3>UPI</h3>
                                                        <div className='row'>
                                                            <div className="col-sm-6 col-12">
                                                                <div className='account-detail-deposit'>
                                                                    <div className='depositbank-detail'>
                                                                        <ul>
                                                                            <li className='d-flex flex-wrap justify-content-between'>
                                                                                <div className='d-flex flex-wrap bankname'>
                                                                                    <div className="bankName">Name :</div>
                                                                                    <span className="bankSubName">Dataniya Rajesh</span>
                                                                                </div>
                                                                                <div className='bankcopy text-end'><IoCopyOutline /></div>
                                                                            </li>
                                                                            <li className='d-flex flex-wrap justify-content-between'>
                                                                                <div className='d-flex flex-wrap bankname'>
                                                                                    <div className="bankName">UPI ID :</div>
                                                                                    <span className="bankSubName">dataniyarajesh@axl</span>
                                                                                </div>
                                                                                <div className='bankcopy text-end'><IoCopyOutline /></div>
                                                                            </li>
                                                                            <li className='d-flex flex-wrap justify-content-between'>
                                                                                <div className='d-flex flex-wrap bankname'>
                                                                                    <div className="bankName">Min Amount :</div>
                                                                                    <span className="bankSubName">100</span>
                                                                                </div>
                                                                                <div className='bankcopy text-end'><IoCopyOutline /></div>
                                                                            </li>
                                                                            <li className='d-flex flex-wrap justify-content-between'>
                                                                                <div className='d-flex flex-wrap bankname'>
                                                                                    <div className="bankName">Max Amount :</div>
                                                                                    <span className="bankSubName">1000000</span>
                                                                                </div>
                                                                                <div className='bankcopy text-end'><IoCopyOutline /></div>
                                                                            </li>
                                                                            <li>
                                                                                <img className="qrimage" src={qr} alt="" />
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="wpBtnWrapper">
                                                                    <button className="wpBtn w-100 d-flex flex-column align-items-center">FOR PAYMENT RELATED ISSUES <span className="wpAnimation"><RiWhatsappLine /></span></button>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col-12 mt-4 sm-mt-0">
                                                                <div>
                                                                    <div className='depositform-data'>
                                                                        <div className='input'>
                                                                            <label>Unique Transaction Reference <span>*</span></label>
                                                                            <input type='text' placeholder='6 to 12 Digits UTR Number' className='d-block depositInp' />
                                                                            <span>Enter valid UTR number.</span>
                                                                        </div>

                                                                        <div className="my-3 upload-container">
                                                                            <label htmlFor="paymentProof" className="form-label">
                                                                                Upload Your Payment Proof{" "}
                                                                                <span className="required-text">[Required]</span>
                                                                            </label>
                                                                            <input type="hidden" name="hiddenInput" />
                                                                            <input
                                                                                type="file"
                                                                                className="form-control custom-file-input"
                                                                                id="paymentProof"
                                                                                aria-describedby="paymentProofHelp"
                                                                            />
                                                                            <div></div>
                                                                            <div>
                                                                                <p></p>
                                                                            </div>
                                                                        </div>

                                                                        <div className='input'>
                                                                            <label>Amount  <span>*</span></label>
                                                                            <input type='number' placeholder='6 to 12 Digits UTR Number' className='d-block depositInp' />
                                                                            <span>Enter valid UTR number.</span>
                                                                        </div>
                                                                        <div><button className="submitBtn">SUBMIT</button></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    } */}
                                    </div>)) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-12 pe-lg-0 mt-3">
                    <div className="row mx-0">
                        <div className="col-12 px-0">
                            <div className="scoreTabBest sideTable">
                                <div className="table-responsive overflow-y-auto h-100">
                                    <table className="table-bordered border-black">
                                        <thead>
                                            <tr className="bg-black text-white text-nowrap text-center">
                                                <th className="p-2">Transaction No.</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                                <th>Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userchatlistData && userchatlistData.length > 0 ? userchatlistData.map((element, index) => (
                                                <tr key={index} style={{ backgroundColor: '#f3f2f6', textWrap: 'nowrap' }}>
                                                    <td className="px-2">{element.UTR}</td>
                                                    <td className="px-2">{element.amount}</td>
                                                    <td className="px-2">
                                                        <div className="d-flex justify-content-center align-items-center m-2"
                                                            style={{
                                                                border: "2px solid",
                                                                borderColor:
                                                                    element.status === "D"
                                                                        ? "#FF0000"
                                                                        : element.status === "S"
                                                                            ? "#008000"
                                                                            : element.status === "A"
                                                                                ? "#0000FF"
                                                                                : "#FF0000",
                                                                color: "black",
                                                                padding: "5px",
                                                                borderRadius: "5px",
                                                            }}
                                                        >
                                                            {element.status === "D"
                                                                ? "Decline"
                                                                : element.status === "S"
                                                                    ? "Success"
                                                                    : element.status === "A"
                                                                        ? "Approve"
                                                                        : "Pending"}
                                                        </div>
                                                        {/* {element.status === "D" ? "Decline" : element.status === "S" ? "Success" : "Pending"} */}
                                                    </td>
                                                    <td className="px-2">{moment.unix(element.created_at).format("DD-MM-YYYY HH:MM:SS A")}</td>
                                                    <td className="px-2">{element.reason ? element.reason : "-"}</td>
                                                </tr>
                                            )) : null}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Deposit;