import Slider from "react-slick"
import Footer from "../../../components/Footer/Footer"
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import NoDataAvailable from "../../../components/NodataAvailable/NodataAvailable";
import { IoMdSearch } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { ourSlides, matchSeriesList } from "../../../redux/_reducers/_reducers";
import Blink from "../../../components/BlinkComponent/Blink";
import RightSidebar from "../../../components/RightSidebar/RightSidebar";

function AllSportsHighlight() {
    const navigate = useNavigate();
    let { sportsId, eventId } = useParams();
    const [activeNav, setActiveNav] = useState(4);
    const [matchlistData, setMatchlistData] = useState("");
    const [matchSerieslistData, setMatchSerieslistData] = useState("");
    const [slideData, setSlideData] = useState("");
    let user = JSON.parse(localStorage.getItem('user'));
    const { sportmatchlist, ourSlidesData, matchSeriesListData } = useSelector((state) => state.authentication);
    const dispatch = useDispatch();
    const { sportId } = useParams();
    const loggedIn = localStorage.getItem("user");
    useEffect(() => {
        if (sportmatchlist && sportmatchlist?.sportlist && sportmatchlist.sportlist?.data) {
            if (sportsId) {
                const filteredData = sportmatchlist.sportlist.data.filter(item => item.sportId == sportsId);
                return setMatchlistData(filteredData);
            } else {
                setMatchlistData(sportmatchlist.sportlist.data);
            }
        }
    }, [sportmatchlist, sportsId]);

    useEffect(() => {
        const payload = { limit: 100, pageno: 1, series_id: 0, sport_id: sportsId, type: 'h' };
        dispatch(matchSeriesList(payload));
    }, [sportsId]);

    useEffect(() => {
        if (matchSeriesListData) {
            setMatchSerieslistData(matchSeriesListData);
        }
    }, [matchSeriesListData]);
    const formatDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const options = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric', 
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
        };
        return date.toLocaleString('en-GB', options);
      };
    // useEffect(() => {
    //     const { InplayMatches = [], UpCommingMatches = [] } = matchSerieslistData || {};
    //     if (!InplayMatches?.length && !UpCommingMatches?.length) {
    //         return; 
    //     }

    //     const filteredInplayMatches = InplayMatches.filter(
    //         (match) => Number(match?.sport_id) === 'sportsId'
    //     );
    //     const filteredUpcomingMatches = UpCommingMatches.filter(
    //         (match) => Number(match?.sport_id) === 'sportsId'
    //     );

    //     setFilteredInplayMatchData(filteredInplayMatches)
    //     setFilteredUpcomingMatchData(filteredUpcomingMatches);
    // }, [matchSeriesListData,sportsId]); 


    useEffect(() => {
        if (!ourSlidesData) {
            const payload = { domain_id: 1 };
            dispatch(ourSlides(payload));
        }
    }, [dispatch, ourSlidesData]);

    useEffect(() => {
        if (ourSlidesData) {
            setSlideData(ourSlidesData);
        }
    }, [ourSlidesData]);


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    return (
        <div className="row mx-0">
            <div className="score-tabcontent-work">
                <Slider {...settings} className="tab-content-slider">
                    {slideData && slideData.length > 0 ? (
                        slideData.map((slide) => (
                            <div key={slide?.id} className="item">
                                <img src={slide?.attachment} alt={slide?.alt || "Slide Image"} />
                            </div>
                        ))
                    ) : (
                        <div>No slides available</div>
                    )}
                </Slider>

                <div className="tab-main-work">
                    <div className="highlights"><h4>Highlights</h4></div>
                    <div className="inner-data">
                        {matchSerieslistData && (matchSerieslistData.InplayMatches.length > 0 || matchSerieslistData.UpCommingMatches.length > 0) ? (
                            <table className="bet-table-chart">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th><div className="width"><span>1</span></div></th>
                                        <th><div className="width"><span>2</span></div></th>
                                        <th><div className="width"><span>x</span></div></th>
                                        {/* <th></th> */}
                                    </tr>
                                </thead>

                                {[
                                    ...matchSerieslistData.InplayMatches,
                                    ...matchSerieslistData.UpCommingMatches
                                ].map((mList, index) => (
                                    <tbody key={index}>
                                        <tr>
                                            <td className="bet-table-chart-p">
                                                <p>
                                                    <span>
                                                        {loggedIn ? (
                                                            <Link
                                                                className=" align-items-center gap-1 fontSize17"
                                                                to={`/app/view-details/${mList?.sport_id}/${mList?.match_id}`}
                                                            >
                                                                {mList?.name}
                                                                <span className="text-secondary font-semibold mx-1">
                                                                    {formatDate(mList?.start_date)}
                                                                </span>
                                                                {/* Render Blink only for InplayMatches */}
                                                                {matchSerieslistData.InplayMatches.includes(mList) && <Blink />}
                                                            </Link>
                                                        ) : (
                                                            <Link
                                                                className="align-items-center gap-1 fontSize17"
                                                                to={`/viewdetail/${mList?.sport_id}/${mList?.match_id}`}
                                                            >
                                                                {mList?.name}
                                                                <span className="text-secondary font-semibold mx-1">
                                                                    {formatDate(mList?.start_date)}
                                                                </span>
                                                                {/* Render Blink only for InplayMatches */}
                                                                {matchSerieslistData.InplayMatches.includes(mList) && <Blink />}
                                                            </Link>
                                                        )}
                                                    </span>
                                                    {/* <span>
                                                        {loggedIn ?
                                                            <Link className="d-flex align-items-center gap-1 fontSize17" to={`/app/view-details/${mList?.sport_id}/${mList?.match_id}`}>
                                                                {mList?.name} <span className="text-secondary font-semibold mx-2">{formatDate(mList?.start_date)}</span>
                                                                <Blink />
                                                            </Link> :
                                                            <Link className="d-flex align-items-center gap-1 fontSize17" to={`/viewdetail/${mList?.sport_id}/${mList?.match_id}`}>
                                                            {mList?.name} <span className="text-secondary font-semibold mx-2">{formatDate(mList?.start_date)}</span>
                                                            <Blink />
                                                            </Link>
                                                        }
                                                    </span> */}
                                                </p>
                                            </td>
                                            <td>
                                                <td>
                                                    <div className="d-flex flex-wrap gap-2 justify-content-center bm-s-f">
                                                        {Number(sportsId) === 4 && (
                                                            <div className="cricketSpecificUi d-flex align-items-center gap-2">
                                                                {/* <span className="cricketS">S</span> */}
                                                                {Number(sportsId) === 4 && (
                                                                    <span className="cricketS">M</span>
                                                                )}
                                                                 {Number(sportsId) === 4 &&  (
                                                                    <span className="cricketBm">BM</span>
                                                                )}
                                                                 {Number(sportsId) === 4 && (
                                                                    <span className="cricketF">F</span>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </td>
                                            <td>
                                                <div className="width">
                                                    <span>{mList?.value1 || "1.2"}</span>
                                                    <span>{mList?.value2 || "1.2"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="width">
                                                    <span>{mList?.value3 || "1.2"}</span>
                                                    <span>{mList?.value4 || "1.2"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="width">
                                                    <span>{mList?.value5 || "1.2"}</span>
                                                    <span>{mList?.value6 || "1.2"}</span>
                                                </div>
                                            </td>
                                            {/* <td className="pin">
                                                <a href="#" ><i className="fa fa-thumb-tack" aria-hidden="true"></i></a>
                                            </td> */}
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        ) : (
                            <NoDataAvailable />
                        )}
                    </div>

                </div>


                <Footer />


            </div>
            {/* <div className="col-lg-3 score-tabbets ">
                <RightSidebar />
            </div> */}

        </div>
    )
}

export default AllSportsHighlight
