import React, { useEffect, useState } from "react";
import { ImCross } from "react-icons/im";
import { checkAvailable, existMobile, signUp } from "../../redux/_reducers/_reducers";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { CONST } from "../../middelware/Const";

function Signup({ SignupOpen, CloseSignup, setConfirmPopup }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // State for form inputs
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [mobile, setMobile] = useState("");
    const [isAPICalled, setIsAPICalled] = useState(false);
    const { checkAvailableData, existMobileData, signUpData, defaultSettingData } = useSelector(
        (state) => state.authentication
    );
    const SiteTitle = defaultSettingData?.find((item) => item.key === "site.TITLE");

    const handleMobileFocus = () => {
        if (!username) {
            toast.error("Please fill username");
        } else if (!isAPICalled) {
            const payload = { username: username };
            dispatch(checkAvailable(payload));
            setIsAPICalled(true);
        }
    };

    const handlePasswordFocus = () => {
        if (!mobile) {
            toast.error("Please fill mobile number");
        } else if (!isAPICalled) {
            const existMobilePayload = { mobile: mobile };
            dispatch(existMobile(existMobilePayload));
            setIsAPICalled(true);
        }
    };

    const handleSignupClick = (e) => {
        e.preventDefault();
        if (username && mobile && password) {
            const signUpPayload = { name: username, username: username, email: `${username}@${SiteTitle}.com`, mobile: mobile, password: password, confirmpassword: password };
            dispatch(signUp(signUpPayload));
            navigate('/');
        } else {
            toast.error("Please fill all fields");
        }
    };

    const sitelogo = defaultSettingData?.find((item) => item.key === "site.LOGO");
    return (
        <div className={`signup-main position-fixed top-0 `}>
            <div className="signup-second d-flex align-items-center h-100 position-relative">
                <div className="signup-send">
                    <img src={`${CONST.IMAGE_URL}${sitelogo?.value}`} />
                    <h4 className="text-center">Sign up</h4>
                    <form>
                        <div className="singup-input">
                            <input type="text" placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="singup-input">
                            <div className="phone-input-container">
                                <img src="/images/FlagOfIndia.svg" alt="India Flag" />
                                <span>+91</span>
                                <input type="number" placeholder="1234 567890"
                                    value={mobile}
                                    onFocus={handleMobileFocus}
                                    onChange={(e) => setMobile(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="singup-input">
                            <input onClick={handleMobileFocus} type="password" placeholder="Password"
                                value={password}
                                onFocus={handlePasswordFocus}
                                onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <button onClick={handleSignupClick}>Sign Up</button>
                    </form>
                </div>
                <Link to='/' className="position-absolute signup-cross" onClick={CloseSignup}>
                    <ImCross /></Link>
            </div>
        </div>
    )
}


export default Signup;