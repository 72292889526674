import { toast } from "react-toastify";
import { authHeader } from "../../middelware/Auth";
import { CONST } from "../../middelware/Const";
import { handleResponse } from "../../middelware/HandalResponse";
import axios from "axios";



async function login(data) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `login`, requestOptions);

    const user = await handleResponse(response);
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
      return { userinfo: user };
    }
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function defaultSetting() {
  const header = {
    'Content-Type': 'application/json',
  };
  const requestOptions = {
    method: "GET",
    headers: header,
    // body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `default-setting`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function customerDetails(data) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `customer-details`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function userchatlist(data) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `user-chat-list`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function checkAvailable(data) {
  const header = {
    'Content-Type': 'application/json',
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `check-avaliable`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function signUp(data) {
  const header = {
    'Content-Type': 'application/json',
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `sign-up`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function existMobile(data) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `userexistornotmobile`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function walletBalance(navigate) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    // body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `wallet-balance`, requestOptions);

    if (response?.status == 200) {
      const result = await response.json();
      return result;
    } else {
      localStorage.clear()
      navigate("/")
      // window.location.href = '/';
    }

  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function updateInfo(data) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `update-info`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function accountStatement(data) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `statement`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function myBetList(data) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `my-bet-list`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function profitLoss(data) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `my-profit-loss`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function ourSlides(data) {
  const header = {
    'Content-Type': 'application/json',
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `our-slide`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function eventGameList(data) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `event-game-list`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function gameEventList(data) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `game-event-list`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function SportMatchOuterList(data) {
  const header = {
    'Content-Type': 'application/json',
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `getSportOuterList`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function matchSeriesList(data) {
  const header = {
    'Content-Type': 'application/json',
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `getseiresMatchsList`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function depositRequest(data) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `my-deposit-request`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function getCasinoList(data) {
  const header = {
    'Content-Type': 'application/json',
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `getCasinoList`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function casinoListByCategory(data) {
  const header = {
    'Content-Type': 'application/json',
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `getCasinoListByCategory`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

async function sportMatchList(data) {
  const header = {
    'Content-Type': 'application/json',
    // 'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `sports/matchList`, requestOptions);
    const user = await handleResponse(response);
    return { sportlist: user };

  } catch (error) {

    console.error("Sport Match List", error);
    return Promise.reject(error);
  }
}

async function getMatchDetails(data) {

  try {
    const response = await axios.post(CONST.BACKEND_URL + 'sports/sportByMarketId', data);

    // const matchDetails = await handleResponse(response);
    return response.data;
  } catch (error) {
    console.error('Get Match Details error:', error);
    return Promise.reject(error);
  }
}

async function getStatement(data) {
  try {
    let header = {
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    };
    const response = await axios.post(CONST.BACKEND_URL + 'user/userStatement', data, {
      headers: header
    });

    // const user = await handleResponse(response.data);
    return response.data
  } catch (error) {
    console.error('Error fetching statement:', error);
    throw error;
  }
}

async function changePassword(data) {
  try {
    let header = {
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    };
    const response = await axios.patch(CONST.BACKEND_URL + 'user/updateUserPassword', data, {
      headers: header
    });
    let userObj = {
      changePassword: response.data.message
    };

    return userObj;

  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
}

async function betHistory(data) {
  let header = new Headers({
    'Content-Type': 'application/json',
    "Authorization": authHeader().Authorization
  });

  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };

  try {
    let response = await fetch(CONST.BACKEND_URL + `sports/betsList`, requestOptions);
    let responseData = await handleResponse(response);

    let userObj = {
      betHistory: responseData.data
    };
    return userObj;
  } catch (error) {
    console.error('Error fetching bet history:', error);
    throw error;
  }
}


async function getstackUpdate(data) {
  try {
    let header = {
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    };
    const response = await axios.post(CONST.BACKEND_URL + 'single-click-update-amount', data, {
      headers: header
    });
    // const user = await handleResponse(response.data);
    return response
  } catch (error) {
    console.error('Error fetching statement:', error);
    throw error;
  }
}

async function getUserLedger(data) {
  try {
    let header = {
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    };
    const response = await axios.post(CONST.BACKEND_URL + 'user/userLedger', data, {
      headers: header
    });
    // const user = await handleResponse(response.data);
    return response.data
  } catch (error) {
    console.error('Error fetching statement:', error);
    throw error;
  }
}

async function uploadepositeFile(data) {
  try {
    let header = {
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    };
    const response = await axios.post(CONST.BACKEND_URL + 'uploadDepositeFile', data, {
      headers: header
    });
    // const user = await handleResponse(response.data);
    return response.data
  } catch (error) {
    console.error('Error fetching statement:', error);
    throw error;
  }
}

async function ourPages(data) {
  try {
    let header = {
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    };
    const response = await axios.post(CONST.BACKEND_URL + 'our-page', data, {
      headers: header
    });
    // const user = await handleResponse(response.data);
    return response.data
  } catch (error) {
    console.error('Error fetching statement:', error);
    throw error;
  }
}

async function ourPagesShow(data) {
  try {
    let header = {
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    };
    const response = await axios.post(CONST.BACKEND_URL + 'our-page-show', data, {
      headers: header
    });
    // const user = await handleResponse(response.data);
    return response.data
  } catch (error) {
    console.error('Error fetching statement:', error);
    throw error;
  }
}

function logout() {
  localStorage.removeItem('user');
  const toastId = toast.success("User! Logout successfully.");
  setTimeout(() => toast.dismiss(toastId), 1000);
}

export const userServices = {
  login,
  defaultSetting,
  customerDetails,
  userchatlist,
  ourSlides,
  SportMatchOuterList,
  matchSeriesList,
  sportMatchList,
  logout,
  getMatchDetails,
  getStatement,
  changePassword,
  betHistory,
  getUserLedger,
  casinoListByCategory,
  eventGameList,
  getCasinoList,
  gameEventList,
  walletBalance,
  updateInfo,
  accountStatement,
  myBetList,
  profitLoss,
  getstackUpdate,
  checkAvailable,
  existMobile,
  signUp,
  uploadepositeFile,
  depositRequest,
  ourPages,
  ourPagesShow,
};