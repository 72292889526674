import moment from "moment";
import React from "react";
import { RxCross2 } from "react-icons/rx";


const ShowBetsProfitLoss = ({ handleClose, betHistoryData }) => {
    return (
        <div className="profit-loss-showbets">
            <div className="score-tabcontent-work px-2">
                <div className="account-statment-tablwork">
                    <div className="account-statment-table">
                        <div className="roling-table">
                            <div className="rolling-commission-div">
                                <h4>Bet History</h4>
                            </div>
                            <div className="commission-tablemain">
                                <div className="commission-table account-statment-tables">

                                    <table className=" mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                        <p className="text-nowrap">Sports Name</p>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                        <p className="text-nowrap">Event Name</p>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                        <p className="text-nowrap">MarketName</p>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                        <p className="text-nowrap">Slection</p>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                        <p className="text-nowrap">Type</p>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                        <p className="text-nowrap">Odds Req.</p>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                        <p className="text-nowrap">Stack</p>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                        <p className="text-nowrap">Place Time</p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {betHistoryData && betHistoryData.length > 0 ? (
                                                betHistoryData.map((ele, i) => (

                                                    <tr key={i} className={ele.Type === "Back" ? "back-row" : "lay-row"}>
                                                        <td><p className="text-center">{ele.sportName}</p></td>
                                                        <td><p className="text-center">{ele.seriesName}</p></td>
                                                        <td><p className="text-center">{ele.matchName}</p></td>
                                                        <td><p className="text-center">{ele.SelectionName}</p></td>
                                                        <td><p className="text-center">{ele.Type}</p></td>
                                                        <td><p className="text-center">{ele.Odds}</p></td>
                                                        <td><p className="text-center">{ele.Stack}</p></td>
                                                        <td><p className="text-center">{moment.unix(ele.Placed).format("DD-MM-YYYY HH:MM A")}</p></td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colspan="8" className="text-center">
                                                        <p>No data!</p>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                    <div className="profit-losstab-cross" onClick={handleClose}><RxCross2 /></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowBetsProfitLoss;