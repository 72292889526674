import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myBetList, profitLoss } from "../../../redux/_reducers/_reducers";
import moment from "moment";
import RightSidebar from "../../../components/RightSidebar/RightSidebar";
import ReactPaginate from "react-paginate";
import ShowBetsProfitLoss from "../../../components/ShowBetsProfitLoss/showbetsprofitloss";

function ProfitAndLoss() {
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').unix());
    const [toDate, setToDate] = useState(moment().unix());
    const [page, setPage] = useState(100);
    const [profitLossDataa, setProfitLossDataa] = useState([]);
    const [sports, setSports] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [isBetpro, SetBetpro] = useState(false)
    const itemsPerPage = page;
    const [betHistoryData, setBetHistoryData] = useState([]);


    const { profitLossData, myBetListData, loading } = useSelector(
        (state) => state.authentication
    );

    const handleSportsChange = (e) => {
        setSports(e.target.value);
    };

    const handleDateChange = (e, type) => {
        const value = e.target.value;
        const unixTimestamp = Math.floor(new Date(value).getTime() / 1000);
        if (type === 'from') {
            setFromDate(unixTimestamp);
        } else if (type === 'to') {
            setToDate(unixTimestamp);
        }
    };

    useEffect(() => {
        if (profitLossData) {
            setProfitLossDataa(profitLossData);
        }
    }, [profitLossData]);
    useEffect(() => {
        if (myBetListData) {
            setBetHistoryData(myBetListData);
        }
    }, [myBetListData]);

    const handlePageNumber = (event) => {
        setPage(event.target.value);
    };



    const handleSubmit = (e) => {
        if (e) e.preventDefault();
        let data =
        {
            "from_date": fromDate,
            "to_date": toDate,
            "limit": page,
            "sport_id": sports,
            "match_id": "0",
            "betType": "P",
            "pageno": 1
        }
        dispatch(profitLoss(data));
    };

    const handleSubmitBets = (ele) => {

        SetBetpro(true)
        let data =
        {
            "from_date": 0,
            "to_date": 0,
            "limit": "100",
            "sport_id": ele?.sport_id,
            "betType": "C",
            "pageno": 1,
            "market_id": "0",
            "match_id": ele?.match_id
        }
        dispatch(myBetList(data));
    };

    const handleClose = () => {
        SetBetpro(false)
        setBetHistoryData({})
    }

    useEffect(() => {
        handleSubmit();
    }, [page]);

    return (
        <div className="profit-loss-box">
            <div className="p-2">
                <div className="row rollingform1 mx-0">
                    <div className="col-lg-3 col-md-3 col-12 my-profile-select">
                        <div className="col-12 my-profile-select  my-profile-select2">
                            <select value={sports} onChange={handleSportsChange}>
                                <option value="0">Sports</option>
                                <option value="4">Cricket</option>
                                <option value="2">Tennis</option>
                                <option value="1">Football</option>
                                <option value="111">Casino</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3  col-12  my-profile-select">
                        <input className="position-relative abc"
                            type="date" id="date"
                            value={moment.unix(fromDate).format("YYYY-MM-DD")}
                            onChange={(e) => handleDateChange(e, 'from')}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-12 my-profile-select ">
                        <input className="position-relative abc" type="date" id="date"
                            value={moment.unix(toDate).format("YYYY-MM-DD")}
                            onChange={(e) => handleDateChange(e, 'to')}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-12 my-profile-select ">
                        <button onClick={handleSubmit} className="form-btn border-0">Search</button>
                    </div>
                </div>
            </div>

            <div className="account-statment-tablwork px-2">
                <div className="account-statment-table">
                    <div className="roling-table border">
                        <div className="rolling-commission-div">
                            <h4>Profit/Loss</h4>
                        </div>
                        <div className="commission-table account-statment-tables">
                            <div className="account-lable d-flex flex-wrap align-items-center">
                                <p className="mb-0">Show</p>
                                <label className="mx-1">
                                    <select
                                        value={page}
                                        onChange={handlePageNumber}>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </label>
                                <p className="mb-0">entries</p>
                            </div>
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                <p>Match</p>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                <p>Stack</p>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                <p>Profit/Loss</p>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                <p>Commission</p>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                <p>Show Bets</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {profitLossDataa && profitLossDataa.length > 0 ? (
                                        profitLossDataa.map((ele, i) => (
                                            <tr key={i}>
                                                <td className="border text-center">
                                                    {ele?.sportName}{" "}|{" "}{ele?.seriesName} <br />
                                                    {(ele?.matchName)}<br />
                                                    {moment.unix(ele.matchDate).format("MMM,DD YYYY, HH:MM:SS A")}
                                                </td>
                                                <td>
                                                    {ele?.stack}
                                                </td>
                                                <td
                                                    className="border text-center"
                                                    style={{ color: ele?.userPL < 0 ? 'red' : 'green' }}
                                                >
                                                    {ele?.userPL < 0 ? ele?.userPL : ele?.userPL}
                                                </td>
                                                <td className="border text-center">
                                                    {ele?.userComm > 0 ? ele?.userComm : '0'}
                                                </td>
                                                <td className="border text-center">
                                                    <button class="profitLossButton" onClick={() => handleSubmitBets(ele)}>Bets</button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colspan="4" className="text-center">
                                                <p>No data!</p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="col-lg-3 score-tabbets " style={{top:'100px'}}>
            <RightSidebar/>
        </div> */}

            <div className={`betsprofitsoss-all ${isBetpro ? 'd-block' : 'd-none'}`}>
                <ShowBetsProfitLoss handleClose={handleClose} betHistoryData={betHistoryData} />
            </div>
        </div>
    )
}

export default ProfitAndLoss
